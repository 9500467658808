import React from 'react'
import Img from "gatsby-image"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import * as styles from './styles.module.css'
import Parser from "html-react-parser";

const CaseStudyTemplate = ({ data }) => (
  <Layout activeMenuItem={false} headerType={'caseStudy'}>
    <Seo title="Home" keywords={[`simplease`, `user experience`, `agency`]} />

    <div className={styles.title}>
      <Img
        className={styles.heroImage} fluid={data.strapiCasestudy.teaserImage.localFile.childImageSharp.fluid}
      />
      <div className={styles.titleInner}>
        <h1>{Parser(data.strapiCasestudy.title)}</h1>
      </div>
    </div>

    <div className="content-wrapper">
      <div className={styles.content}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data.strapiCasestudy.content}</ReactMarkdown>
      </div>
    </div>


    <div className="content-wrapper secondary">
      <div className="content">
        <h2 className={'hero-heading'}>{data.strapiCasestudy.conclusionLine}</h2>
      </div>
    </div>
  </Layout>
);

export default CaseStudyTemplate;

export const query = graphql`
query CaseStudyTemplate($strapiId: String!) {
  strapiCasestudy(id: { eq: $strapiId }) {
    title,
    teaserImage {
      localFile {
        childImageSharp {
          fluid(
            maxWidth: 2000,
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }  
    },
    content,
    conclusionLine
  }
}
`;

/*

          duotone: {
            highlight: "#71B5E1",
            shadow: "#368BC1"
          }

          */